<template>
  <div>
    <v-row no-gutters class="mt-2">
      <v-col cols="12" md="6">
        <v-list>
          <v-divider />
          <template v-for="company in companies">
            <v-list-item
              ripple
              :key="company.id"
              @click="goToCompany(company)"
            >
              <v-list-item-icon>
                <v-icon>{{ company.type | companyTypeIcon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ company.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ company.type | prettyCompanyType }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider :key="company.id + '_d'" />
          </template>
        </v-list>
      </v-col>
      <v-col cols="12" md="6">
        <v-list>
          <v-divider />
          <template v-for="contact in contacts">
            <v-menu
              v-model="contactMenu[contact.id]"
              max-width="450"
              :close-on-content-click="false"
              :key="contact.id"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-list-item
                  :key="contact.id"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-account</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ contact.fullName }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      Contact {{ contact.type | prettyCompanyType }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <ContactCard
                :user="contact"
                @close="contactMenu[contact.id] = false"
              />
            </v-menu>
            <v-divider :key="contact.id + '_d'" />
          </template>
        </v-list>
      </v-col>
    </v-row>
    <v-row class="mt-2">
      <v-col cols="12">
        <v-list>
          <v-divider />
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-calendar</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                <span v-if="site.wasteReceptionDate">
                  {{ site.wasteReceptionDate | formatDate('date') }}
                </span>
                <span v-else>
                  Aucune
                </span>
              </v-list-item-title>
              <v-list-item-subtitle>
                Date de réception des déchets
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider />
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-history</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ site.createdAt | formatDate }}
              </v-list-item-title>
              <v-list-item-subtitle>
                Date de création du chantier
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider />
        </v-list>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { availableCompanyTypes } from '../../utils/constants'
import ContactCard from '@/components/ContactCard.vue'

export default {
  components: { ContactCard },
  props: {
    site: {
      type: Object,
      required: true
    },
  },
  computed: {
    companies () {
      return availableCompanyTypes
        .map(t => t.key)
        .filter(type => this.site[type])
        .map(type => this.site[type])
    },
    contacts () {
      return availableCompanyTypes
        .filter(type => this.site[type.key + 'Contact'])
        .map(type => ({ ...this.site[type.key + 'Contact'], type: type.id }))
    },
  },
  data: () => ({
    contactMenu: []
  }),
  methods: {
    goToCompany (company) {
      // only admin can navigate between companies
      if (!this.$store.state.auth.user.isAdmin) {
        return
      }
      this.$router.push({ name: 'CompanySites', params: { id: company.id } })
    }
  }
}
</script>